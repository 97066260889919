import Icon from '@ant-design/icons';
import { ReactComponent as referralUrl } from '@/assets/icons/referral-url.svg';
import { useUser } from '@/hooks';
import { isBetweenDate } from '@arcsite/utils';
import tracker from '@/tracker';

const promotionLink = 'https://www.arcsite.com/august-promotion';
const affiliateLink = 'https://affiliate.arcsite.com/';
// EDT timezone
const startDate = '2024-08-20T08:00:00-04:00';
const endDate = '2024-08-30T23:59:59-04:00';

function Promotion() {
  const handleClick = () => {
    tracker.logEvent('web_promotion_click');
  };
  return (
    <a
      className="mr-4 hidden sm:block"
      href={promotionLink}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
    >
      <div className="bg-[#E3571C] text-xs px-2.5 py-2 text-white rounded font-semibold flex items-center">
        <span className="whitespace-nowrap hidden sm:block">
          💰 Claim my offer
        </span>
      </div>
    </a>
  );
}

function Affiliate() {
  return (
    <a
      className="mr-4 hidden sm:block"
      href={affiliateLink}
      target="_blank"
      rel="noreferrer"
    >
      <div className="bg-[#E3571C] text-xs px-2.5 py-2 text-white rounded font-semibold flex items-center">
        <Icon component={referralUrl} className="text-16" />
        <span className="whitespace-nowrap ml-2 hidden sm:block">
          Become an affiliate
        </span>
      </div>
    </a>
  );
}

export default function ArcSiteCampaign() {
  const { showPromotion } = useArcSiteCampaign();
  return showPromotion ? <Promotion /> : <Affiliate />;
}

const useArcSiteCampaign = () => {
  const user = useUser();

  const isTrial = user?.default_org_purchase_type == 'TRIAL';
  const notEstimateAndTakeoff =
    user &&
    user.default_org_user_level !== 'PREMIUM_PLUS' &&
    user.default_org_user_level !== 'TAKEOFF_BASIC';
  const dataLimit = isBetweenDate(startDate, endDate);

  const showPromotion = (isTrial || notEstimateAndTakeoff) && dataLimit;

  return {
    showPromotion,
  };
};
