import { callPostApi } from '@/components/api-common';

export type UserResponse = {
  user_id: string;
  username: string;
  default_org_type: string;
  is_admin: boolean;
  email: string;
  first_name: string;
  last_name: string;
  phone: string | null;
  stripe_customer_id: string;
  expired: boolean;
  default_org_subs_expire_time: number;
  default_org_purchase_type: string;
  expired_without_extension: boolean;
};

export const fetchUser = async (): Promise<UserResponse> => {
  return callPostApi('/user/service/userAuth/user/v2/');
};
