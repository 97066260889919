import { callPostApi } from '@/components/api-common';
import type { Subscription } from './models';

export default async function getBillingSubscriptions() {
  let res: Subscription[];
  if (!window.__MOCK__) {
    res = await callPostApi('user/service/subs/billing_subscriptions/');
  } else {
    res = [
      {
        id: '1',
        stripe_id: '2',
        items: [
          {
            quantity: 5,

            id: 'str',
            stripe_id: 'str',
            plan: {
              id: 'a',
              active: true,
              amount: 123,
              stripe_id: 'sdf',
              interval: 'month',
              interval_count: 123,
              product_id: '1',
            },
          },
        ],
        cancel_at: 123,
        canceled_at: 123,
        current_period: { start: 1, end: 2 },
        schedule: {
          id: 'test',
          stripe_id: 'test',
          subscription_id: '',
          current_phase_period: { start: 1, end: 2 },
          phases: [
            {
              period: { start: 222222222222, end: 3333333333333333 },
              plans: [
                {
                  quantity: 2,
                  plan: {
                    id: 'a',
                    active: true,
                    amount: 123,
                    stripe_id: 'sdf',
                    interval: 'month',
                    interval_count: 123,
                    product_id: '2',
                  },
                },
              ],
              coupon_id: '',
            },
            {
              period: { start: 222222222222, end: 3333333333333333 },
              plans: [
                {
                  quantity: 2,
                  plan: {
                    id: 'a',
                    active: true,
                    amount: 123,
                    stripe_id: 'sdf',
                    interval: 'month',
                    interval_count: 123,
                    product_id: '2',
                  },
                },
              ],
              coupon_id: '',
            },
          ],
        },
        discount: null,
        created: 123,
        status: 'active',
        metadata: {},
      },
    ];
  }

  return res;
}
