import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import A from '@/components/A';

// 需要额外获取company
import { useSelector, connect } from 'react-redux';
import Icon from '@ant-design/icons';
import { Menu, Alert, Button } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { ReactComponent as CompanyIcon } from '@/assets/icons/company.svg';
import { ReactComponent as FormsIcon } from '@/assets/icons/forms.svg';
import { ReactComponent as DrawingPDFIcon } from '@/assets/icons/drawing-pdf-icon.svg';
import { ReactComponent as IntegrationIcon } from '@/assets/icons/integration.svg';
import { ReactComponent as DeveloperIcon } from '@/assets/icons/developer.svg';
import { ReactComponent as ProductIcon } from '@/assets/icons/product.svg';
import { isIpadOS } from '@/utils/platform';
import { RootState } from '@/store';
import { User } from '@/reducers/product-management';

const modules = [
  {
    title: 'Company Account',
    icon: CompanyIcon,
    hide: (user: User) => user.default_org_type == 'INDIVIDUAL',
    children: [
      { title: 'Accounts', path: 'manage_accnt' },
      { title: 'Usage Report', path: 'usage_report' },
    ],
  },
  {
    title: 'Takeoff & Estimate',
    icon: ProductIcon,
    children: [
      { title: 'Products', path: 'product_management' },
      {
        title: 'Estimating',
        path: 'estimating',
        // @ts-expect-error
        hide: (user: User) => !user.features.PRODUCT_TAKEOFF_PRICING_METHOD,
      },
      { title: 'Field Data', path: 'takeoff_form_management' },
      { title: 'Tax Rates', path: 'tax_rates' },
      { title: 'Payments', path: 'payment' },
      { title: 'Currency', path: 'currency' },
      { title: 'Proposal Templates', path: 'proposal-list' },
    ],
  },
  { title: 'Form Templates', path: 'forms', icon: FormsIcon },
  { title: 'Drawing & PDF', path: 'pdf-setting', icon: DrawingPDFIcon },
  {
    title: 'Integrations',
    icon: IntegrationIcon,
    children: [
      {
        title: 'Apps',
        path: 'integrations',
      },
      {
        title: 'Product Manager',
        path: 'product_integration',
      },
      {
        title: 'Wisetack Dashboard',
        path: 'wisetack',
        hide: (user: User) => !user.features.ENABLE_WISETACK,
      },
    ],
  },
  {
    title: 'Developers',
    icon: DeveloperIcon,
    children: [
      {
        title: 'API tokens',
        path: 'apitokens',
      },
      {
        title: 'Webhooks',
        path: 'webhooks',
      },
    ],
  },
];

const flatModules: any[] = [];
for (let mod of modules) {
  if (mod.children) {
    for (let child of mod.children) {
      flatModules.push(child);
    }
  } else {
    flatModules.push(mod);
  }
}

export function Menus() {
  const location = useLocation();
  const paths = flatModules.map(mod => mod.path);
  var activeKeys = paths.filter(path => {
    if (location.pathname.startsWith('/admin/form')) {
      return path == 'forms';
    } else if (location.pathname.includes('proposal-detail')) {
      return path == 'proposal-list';
    }
    return '/admin/' + path == location.pathname;
  });
  const user = useSelector<RootState, User | null>(state => state.user);

  return (
    <Menu
      inlineIndent={16}
      selectedKeys={activeKeys}
      defaultOpenKeys={isIpadOS() ? [] : ['0', '1', '3']}
      mode="inline"
    >
      {modules.map((module, i) => {
        if (module.hide?.(user!)) {
          return null;
        }
        return !module.children ? (
          <Menu.Item key={module.path} className="pl-4">
            <Link to={`/admin/${module.path}`} target="_self">
              <span className="flex items-center -ml-1 text-14 font-medium">
                <Icon className="!text-24" component={module.icon} />
                <span>{module.title}</span>
              </span>
            </Link>
          </Menu.Item>
        ) : (
          <SubMenu
            key={i}
            title={
              <span className="flex items-center -ml-1 text-14 font-medium">
                <Icon className="!text-24" component={module.icon} />
                <span>{module.title}</span>
              </span>
            }
          >
            {module.children.map(child =>
              (child as any).hide?.(user) ? null : (
                <Menu.Item key={child.path}>
                  <Link
                    className="pl-6 text-14 !text-black-55"
                    to={`/admin/${child.path}`}
                  >
                    {child.title}
                  </Link>
                </Menu.Item>
              )
            )}
          </SubMenu>
        );
      })}
    </Menu>
  );
}

const tips = [
  {
    url: 'https://support.arcsite.com/en/articles/7258300-adding-additional-users-to-your-account',
    icon: CompanyIcon,
    title: 'How do I add or remove users to my company account?',
  },
  {
    url: 'https://support.arcsite.com/en/collections/3953117-products',
    icon: ProductIcon,
    title: 'How do I use the takeoff and estimate features in ArcSite?',
  },
  {
    url: 'https://support.arcsite.com/en/articles/7258206-creating-and-using-custom-forms',
    icon: FormsIcon,
    title: 'How do I create custom forms?',
  },
  // { url: '', icon: IntegrationIcon, title: 'How to setup QuickBook integration?' },
];

export function Dashboard() {
  return (
    <div className="">
      <h4 className="text-center mt-24 text-gray-700">Advanced Settings</h4>
      <div className="flex items-center flex-col">
        <ul className="list-none">
          {tips.map((tip, i) => (
            <li key={i} className="my-5">
              <a
                className="inline-flex items-center text-base"
                style={{ color: '#1391ff' }}
                href={tip.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  style={{ fontSize: '20px', marginRight: '4px' }}
                  component={tip.icon}
                />
                {tip.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function Nav() {
  const location = useLocation();
  const path = location.pathname.slice('/admin/'.length);
  let title;

  if (path.endsWith('form/')) {
    title = 'Create Form Templates';
  } else if (/form\/\w+/.test(path)) {
    title = 'Form Template';
  } else if (path.includes('proposal-detail')) {
    title = (
      <span>
        <Link style={{ color: '#999' }} to={`/admin/proposal-list`}>
          Proposal Templates / &nbsp;
        </Link>
        <span>Proposal Detail</span>
      </span>
    );
  } else {
    title = flatModules.find(m => m.path == path)?.title;
  }

  return (
    <div>
      <div className="text-14">
        <Link to="/app/projects">Home</Link> /{' '}
        <Link to="/admin">Advanced Settings</Link>
        {title && (
          <>
            &nbsp;/ <span className="text-black-100">{title}</span>
          </>
        )}
      </div>
    </div>
  );
}
function JumpToSubscribeButton() {
  const navigate = useNavigate();
  return (
    <Button
      type="primary"
      onClick={() => {
        navigate('/app/subscribe');
      }}
    >
      View Subscription Plans
    </Button>
  );
}

const DEFAULT_FEATURENAME = {
  PRODUCT_TAKEOFF: 'Takeoff & Estimate',
  PRODUCT_TAKEOFF_PRICING_METHOD: 'Estimating',
  // CAN_MANAGE_COLLABORATOR: 'Manage Collaborator',
  DIY_PROPOSAL_TEMPLATE: 'Customized Sales Proposal Templates',
  CUSTOMIZE_FORM_TEMPLATE: 'Custom Forms',
  THIRD_PARTY_INTEGRATION: '3rd Party Services Integration',
};

type WrapperProps = {
  // using `interface` is also ok
  user: User | null;
};

function WithFeatureCheck(
  Component: React.ComponentType,
  feature: keyof typeof DEFAULT_FEATURENAME,
  featureName?: ValueOf<typeof DEFAULT_FEATURENAME>
) {
  class Wrapper extends React.Component<WrapperProps> {
    render() {
      featureName = featureName || DEFAULT_FEATURENAME[feature];
      const { user, ...passThroughProps } = this.props;
      // @ts-expect-error
      if (!user?.features[feature]) {
        return (
          <Alert
            style={{ color: 'red', maxWidth: '800px', margin: '0 auto' }}
            message=""
            description={
              <>
                <div>
                  {featureName} is not available in your current plan. Upgrade
                  to get more out of ArcSite. Call our sales department at{' '}
                  <A href="tel:1-888-266-1843">1-888-266-1843</A>, or email to{' '}
                  <A href="mailto:sales@arcsite.co">sales@arcsite.co</A>
                </div>
                <div className="flex justify-center my-8">
                  <JumpToSubscribeButton />
                </div>
              </>
            }
            type="error"
            closable={false}
          />
        );
      }

      return <Component {...passThroughProps} />;
    }
  }
  return connect((state: RootState) => ({ user: state.user }))(Wrapper);
}

export const ManageAccount = React.lazy(
  /* webpackChunkName: "Manage Account" */ () => import('./ManageAccount')
);
export const UsageReport = React.lazy(
  /* webpackChunkName: "UsageReport" */ () =>
    import('@/components/usage-report')
);

const CategoryProductView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Products" */ '@/components/product/category-product-view'
    )
);
const Estimating = React.lazy(
  () => import(/* webpackChunkName: "Estimating" */ '@/components/estimating')
);
const TaxRates = React.lazy(
  () => import(/* webpackChunkName: "TaxRates" */ '@/components/tax-rates')
);
const Payment = React.lazy(
  () => import(/* webpackChunkName: "Payment" */ '@/components/payment')
);
const CurrencyForm = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CurrencyForm" */ '@/components/takeoff/takeoff-setting/currency'
    )
);

const TakeoffForm = React.lazy(
  () =>
    import(/* webpackChunkName: "TakeoffForm" */ '@/components/takeoff-form')
);
const ProposalList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProposalList" */ '@/components/proposal-templates/proposalList'
    )
);
const Tokens = React.lazy(
  () =>
    import(/* webpackChunkName: "Apitokens" */ '@/components/developers/tokens')
);
const Webhooks = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Webhooks" */ '@/components/developers/webhooks'
    )
);
const Integrations = React.lazy(
  () =>
    import(/* webpackChunkName: "Integrations" */ '@/components/integrations')
);
export const ProductIntegration = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ProductIntegration" */ '@/components/integrations/product-integration'
    )
);
export const DrawingAndPDF = React.lazy(
  () =>
    import(/* webpackChunkName: "DrawingAndPDF" */ '@/components/drawing-pdf')
);

export const FormTemplate = React.lazy(
  () => import(/* webpackChunkName: "FormTemplate" */ '@/pages/FormTemplate')
);

export const TokensView = WithFeatureCheck(
  Tokens,
  'THIRD_PARTY_INTEGRATION',
  'Developer API'
);
export const WebhooksView = WithFeatureCheck(
  Webhooks,
  'THIRD_PARTY_INTEGRATION',
  'Developer API'
);
export const Wisetack = React.lazy(
  () => import(/* webpackChunkName: "Wisetack" */ '@/components/wisetack')
);

export const Products = WithFeatureCheck(
  CategoryProductView,
  'PRODUCT_TAKEOFF'
);
export const EstimatingView = WithFeatureCheck(
  Estimating,
  'PRODUCT_TAKEOFF_PRICING_METHOD'
);
// export const FormView = WithFeatureCheck(Form, 'CUSTOMIZE_FORM_TEMPLATE');
export const FormTemplateView = WithFeatureCheck(
  FormTemplate,
  'CUSTOMIZE_FORM_TEMPLATE'
);

export const ProductIntegrationView = WithFeatureCheck(
  ProductIntegration,
  'THIRD_PARTY_INTEGRATION'
);

export const TaxRatesView = WithFeatureCheck(TaxRates, 'PRODUCT_TAKEOFF');
export const PaymentView = WithFeatureCheck(Payment, 'PRODUCT_TAKEOFF');
export const CurrencyView = WithFeatureCheck(CurrencyForm, 'PRODUCT_TAKEOFF');
export const TakeoffFormView = WithFeatureCheck(TakeoffForm, 'PRODUCT_TAKEOFF');
export const ProposalListView = WithFeatureCheck(
  ProposalList,
  'DIY_PROPOSAL_TEMPLATE'
);

export const IntegrationsView = WithFeatureCheck(
  Integrations,
  'THIRD_PARTY_INTEGRATION'
);
