import { fetchUser } from '@/api/user';
import { callPostApi } from '@/components/api-common';

const signUp = (params: any) => {
  return callPostApi('/user/service/userAuth/signUp/v2/', params);
};

const inviteUser = (params: { org_name: number; emails: Array<string> }) => {
  return callPostApi(
    '/user/service/userAuth/convert_to_company_and_invte_members/',
    params
  );
};

const resetPwd = (params: {
  k?: string;
  user_id: string;
  password: string;
}) => {
  return callPostApi('/user/service/userAuth/resetpwd/', params);
};

const verifyResetCode = (params: {
  k?: string;
}): Promise<{
  user_id: string;
  username: string;
}> => {
  return callPostApi('/user/service/userAuth/verify_pwd_code/', params);
};

const checkLogin = () => {
  return fetchUser();
};

const login = (params: { username: string; password: string }) => {
  return callPostApi('/user/service/userAuth/login/v2/', params);
};

const forgetpwd = (params: { username: string }) => {
  return callPostApi('/user/service/userAuth/forgetpwd/', params);
};

export const getEverLoginApp = (): Promise<{
  value: boolean;
}> => {
  return callPostApi('/user/service/userAuth/if_user_ever_login_app/');
};

export const getCheckUserNameAvailable = (
  username: string
): Promise<{ available: boolean }> => {
  return callPostApi('/user/service/userAuth/if_username_available/', {
    username,
  });
};

type SignupCategory = {
  signup_industry_category: string;
  industries: string[];
};

export const getSignupCategories = (): Promise<SignupCategory[]> => {
  return callPostApi('/user/service/signup_industries/');
};

export default {
  signUp,
  inviteUser,
  resetPwd,
  verifyResetCode,
  checkLogin,
  login,
  forgetpwd,
  getCheckUserNameAvailable,
  getSignupCategories,
};
